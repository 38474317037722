import React from 'react';
import { InfoLine, Nav, HeaderSlider, HeroBackground } from '../index';
import { useLocation } from 'react-router-dom';

const Header = () => {
     const { pathname } = useLocation();

     return (
          <div className='header grid'>
               <InfoLine />
               <Nav />
               { pathname === '/' ? <HeaderSlider /> : <HeroBackground /> }
          </div>
     )
};

export default Header;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import provNom from '../../assets/images/prov-nom.webp';

const PrNomPro = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Federal skilled trades</title>
                    <meta name="description" content="There are many provinces who is in need qualified workers. Based on their needs
                    they have special offers and criteria to fill their positions" />
               </Helmet>
               <ImageCard img={ provNom }/>
               <TextCard title='Provincial' titleStrong='Nominee Programs'>
                    <p className='textCard__body'>There are many provinces who is in need qualified workers. Based on their needs
                    they have special offers and criteria to fill their positions.
                    </p>
                    <p className='textCard__body'>Every province has different requirements and eligibility criteria. Provinces work in
                    collaboration with Government of Canada.</p>
                    <ul>
                         <li>Alberta Immigrant Nominee Program (AINP)</li>
                         <li>British Columbia Provincial Nominee Program (BC PNP)</li>
                         <li>Manitoba Provincial Nominee Program (MPNP)</li>
                         <li>New Brunswick Provincial Nominee Program</li>
                         <li>Newfoundland and Labrador Provincial Nominee</li>
                         <li>Northwest Territories Nominee Program (NTNP)</li>
                         <li>Nova Scotia Nominee Program (NSNP)</li>
                         <li>Ontario Immigrant Nominee Program (OINP)</li>
                         <li>Prince Edward Island Provincial Nominee</li>
                         <li>Saskatchewan Immigrant Nominee Program (SINP)</li>
                    </ul>
               </TextCard>
          </React.Fragment>
     )
}

export default PrNomPro
import React from 'react';
import { ImageCard, TextCard } from '../../components';
import citizenshiip from '../../assets/images/citizenship.webp';

const CitizenshipMain = () => {
     return (
          <React.Fragment>
               <ImageCard img={ citizenshiip }/>
               <TextCard title='Citizenship' titleStrong=''>
                    <p className='textCard__body'>Every year Canada welcomes more than 410,000  new permanent residents every year. 
                    This includes all immigration pathways.  
                    </p>
                    <p className='textCard__body'>More than half of them become proud Canadian citizens. Becoming a citizen is the 
                    important and final step in the immigration process.</p>
                    <p className='textCard__body'>Naturalization is the process of becoming a Canadian citizen. Although physical 
                    presence in Canada is the primary factor in determining residency obligations, there are other scenarios and 
                    circumstances in which residence outside of Canada might be taken into account.</p>
                    <p className='textCard__body'>The ninth-strongest passport in the world is issued by Canada. Why does this matter? 
                    It implies that citizens of Canada can freely travel without a visa. 140 of the 195 countries on the planet 
                    are welcoming  Canadians passport holders without a visa! Steps to become a Canadian citizen:</p>
                    <ul>
                         <li>Meet eligibility criteria</li>
                         <li>Apply for citizenship</li>
                         <li>Citizenship test and interview</li>
                         <li>Oath of citizenship and ceremony</li>
                    </ul>
                    <p>More details regarding Citizenship can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-citizenship.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
};

export default CitizenshipMain;
import React, { useState } from 'react';
import { Spin as Hamburger } from 'hamburger-react';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';

import { menuItems } from '../data/data';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo.png';


const SubMenuMobile = ({ subMenuItems, subMenuItemFunc }) => {
     
     return (
          <React.Fragment>
               {  !subMenuItems 
                    ?    null
                    :    <React.Fragment>
                         {subMenuItems.map(subMenuItem => 
                              <div className='navMobile__subitem flex align-c jus-c' key={ subMenuItem + Math.random() }>
                                   <Link to={`${ subMenuItem.to }`} className='navMobile__subitem flex align-c' onClick={ subMenuItemFunc }>
                                        <IoCheckmarkDoneCircleOutline className='themeIcon'/>{ subMenuItem.title }
                                   </Link>
                              </div>
                         )}
                         </React.Fragment>
               }
          </React.Fragment>
     )
}

const MenuMobile = ({ menuItemFunc, subMenuItemFunc }) => {
     const [subMenuItems, setSubMenuItems] = useState();
     const [ target, setTarget ] = useState(); // GET TARGET TEXT TO COMPARE WITH DATA ITEMS AND SHOW SUB MENU
     const [ subMenuOpen, setSubMenuOpen ] = useState(false);
     
     return (
          <div className='navMobile grid jus-start navMobile__active'>
               { menuItems.map(menuItem => 
                    <div 
                         key={ menuItem + Math.random() } 
                         className='navMobile__menu flex flex-col jus-c'
                    >
                         <div className='navMobile__item flex jus-sp-bt'>
                              <Link to={`${ menuItem.to }`} onClick={ menuItemFunc }>{ menuItem.title }</Link>
                              <BsArrowsAngleExpand className='smallIconRed'
                                   onClick={(e) => {
                                        subMenuOpen ? setSubMenuItems(null) : setSubMenuItems(menuItem.submenu);
                                        setTarget(e.target.parentNode.firstChild.textContent);
                                        setSubMenuOpen(!subMenuOpen);
                                   }}
                              />
                         </div>
                         {target === menuItem.title ? <SubMenuMobile subMenuItems={ subMenuItems } subMenuItemFunc={ subMenuItemFunc }/> : null}
                    </div>
               )}
          </div>
     )
}

const Mobile = () => {
     const [ isOpen, setOpen ] = useState(false);

     return (
          <div className='mobile'>
               <div className='mobile__iconBox'>
                    <Hamburger 
                         toggled={isOpen} 
                         toggle={setOpen} 
                         size={28}
                    />
               </div>
               <a href='/'>
                    <img src={ logo } alt='aspenimmigration logo' className='mobile__logo'/>
               </a>

               { !isOpen 
               ? null
               : <MenuMobile menuItemFunc={() => setOpen(!isOpen)} subMenuItemFunc={() => setOpen(!isOpen)}/>}
          </div>
     )
};

export default Mobile;
import React from 'react';


const ContactCard = ({ icon, title, content, action, children }) => {

     return (
          <div className='grid align-c jus-c jus-it-c contactCard' onClick={() => window.location = `${ action }`}>
               <div className='contactCard__iconBox flex align-c jus-c'>{ icon }</div>
               <div className='contactCard__titleBox'><p>{ title }</p></div>
               <div className='contactCard__contentBox'><p>{ content }</p></div>
               { children }
          </div>
     )
}

export default ContactCard;
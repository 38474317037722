import React from 'react';
import { ContactCard, ContactForm } from '../components'
import { RiPhoneFill,  } from 'react-icons/ri';
import { GrMail } from 'react-icons/gr';
import license from '../assets/images/lic.png';

const Contact = () => {
     return (
          <div className='grid contact'>
               <div className='grid gr-t-c3 align-c jus-c gap-2 contact__contentBox'>
                    <ContactCard 
                         icon={ <RiPhoneFill className='largeIconWhite'/> }
                         title='Phone Number'
                         content='+1(403)-607-5070'
                         action='tel:+14036075070'
                    />
                    <a href='https://college-ic.ca/protecting-the-public/find-an-immigration-consultant' target='_blank' rel='noreferrer'>
                         <img src={ license } alt='license' className='contact__img'/>
                         <p className='flex jus-c ' style={{ fontSize: '1.4rem', color: 'black', align: 'center' }}>Licence RCIC # R709194</p>
                    </a>
                    <ContactCard 
                         icon={ <GrMail className='largeIconWhite'/> }
                         title='Email Address'
                         content='Click to Send Email'
                         action='mailto:oredkina@aspenimmigration.ca'
                    />
               </div>
               <div className='grid gr-t-c3 align-c jus-c gap-2'>
                    <div className='gr-col-full flex align-c jus-c contact__formBox'>
                         <ContactForm />
                    </div>
               </div>
          </div>
     )
}

export default Contact
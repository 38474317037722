import React from 'react';
import logo from '../../assets/images/logo.png';

const Logo = ({ styles }) => {
     return (
          <a href='/' className={`logo ${styles}`}>
               <img src={ logo } alt='aspenimmigration logo' />
          </a>
     )
}

export default Logo;
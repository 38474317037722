import React, { useContext } from 'react';
import { Spin as Hamburger } from 'hamburger-react';
import { ThemeContext } from '../../App';
import { ContactForm } from '../index';

const ContactFormPopWindow = () => {
     const { contactFormActive, contactFormToggle } = useContext(ThemeContext);
     
     return (
          <div className='popWindow flex align-c jus-c'>
               <div className='assessmentForm__iconBox'>
                    <Hamburger 
                         toggled={contactFormActive} 
                         onToggle={contactFormToggle} 
                         size={28}
                    />
               </div>
               <div className='grid gr-t-c3 align-c jus-c gap-2'>
                    <div className='gr-col-full flex align-c jus-c contact__formBox'>
                         <ContactForm />
                    </div>
               </div>
          </div>
     )
};

export default ContactFormPopWindow;
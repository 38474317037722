import React, { useContext } from 'react';
import { ThemeContext } from '../../App';
import { ThemeButton } from '../index';


const AssessmentCard = ({ img, title, body, to, buttonBody  }) => {
     const { assessmentFormToggle } = useContext(ThemeContext)

     return (
          <div className='assessmentCard grid gr-t-c2-40-60'>
               <div className='assessmentCard__imgBox'>
                    <img src={ img } alt='visa assessment' />
               </div>
               <div className='assessmentCard__contentBox grid gap-1 align-c'>
                    <h3 className='assessmentCard__contentBox-title'>{ title }</h3>
                    <p className='assessmentCard__contentBox-body'>{ body }</p>
                    <ThemeButton 
                         to={ to }
                         body={ buttonBody }
                         func={assessmentFormToggle}
                    />
               </div>
          </div>
     )
};

export default AssessmentCard;
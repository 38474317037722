import React from 'react';
import { ImageCard, TextCard } from '../../components';
import student from '../../assets/images/student.webp';

const StudyMain = () => {
     return (
          <React.Fragment>
               <ImageCard img={ student }/>
               <TextCard title='Study' titleStrong='Program'>
                    <p className='textCard__body'>Thousands of applicants from all over the world hope 
                    to be accepted into one of Canada's schools or universities and receive a study permit. 
                    For many  students, studying in Canada is an exciting and fulfilling experience.
                    </p>
                    <p className='textCard__body'>You must consider entry dates, application deadlines, 
                    student visa processing timelines, and English language requirements once you've 
                    picked what to study.
                    </p>
                    <p className='textCard__body'>Short-term summer language classes, continuing education, 
                    and exchange programmes are available in Canadian colleges and universities in addition 
                    to BA, MBA, PhD, certificate, and diploma programmes.
                    </p>
                    <p className='textCard__body'>Each year, the primary intake occurs in September, however 
                    many colleges and universities have a lesser influx in January or during the summer term. 
                    To study in Canada, the majority of foreign nationals require a study permit.
                    </p>
                    <p className='textCard__body'>To apply for a study permit, you'll need the following documents:</p>
                    <ul>
                         <li>An acceptance letter from a Designated Learning Institution (DLI)</li>
                         <li> a valid passport or travel document demonstrating that you have 
                              sufficient funds to cover tuition, living expenses for yourself 
                              and any family members accompanying you to Canada, and return 
                              transportation for yourself and any family members accompanying 
                              you to Canada.
                         </li>
                         <li>be a law-abiding person with no criminal record and pose no threat to Canada's security.</li>
                         <li>be in good health.</li>
                         <li>Leave Canada at the end of your studies</li>
                    </ul>
                    <p>More details regarding Study Program can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
};

export default StudyMain;
import React from 'react';
import { ThemeButton } from '../components';

const NotFound = () => {
     return (
          <div className='grid jus-c align-c notfound'>
               <h1 className='flex jus-c notfound__title'>Page not found. Please return to main page or pick one of the manu options</h1>
               <ThemeButton body='Proceed to the main page' to='/' />
          </div>
     )
}

export default NotFound
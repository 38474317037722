import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import people from '../../assets/images/people.webp';

const ExpressEntry = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Express Entry</title>
                    <meta name="description" content="Express Entry is online system to manage immigration applications. 
                         Applicants must meet criteria one of the 3 immigration programs to qualify" />
               </Helmet>
               <ImageCard img={ people }/>
               <TextCard title='Express' titleStrong='Entry'>
                    <p className='textCard__body'>Canada welcomes more than 210,000 new immigrants each year. 
                         There are different pathways to immigrate to Canada and one the ways is Express Entry.
                    </p>
                    <p className='textCard__body'>Express Entry is online system to manage immigration applications. 
                         Applicants must meet criteria one of the 3 immigration programs to qualify:</p>
                    <ul>
                         <li>Canadian Experience Class</li>
                         <li>Federal Skilled Worker Program</li>
                         <li>Federal Skilled Trades Program</li>
                    </ul>
                    <p className='textCard__body'>The Express Entry includes two steps:</p>
                    <ul>
                         <li>Complete an online Express Entry profile</li>
                         <li>Receive invitation to apply for permanent residence from IRCC.</li>
                    </ul>
                    <p className='textCard__body'>Submitting application through Express Entry doesn’t guarantee 
                         invitation to apply. All applicants must meet eligibility and admissibility requirements.</p>
                    <p> More details regarding Express Entry can be found 
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
};

export default ExpressEntry;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import canEx from '../../assets/images/can-ex.webp';

const CanExpClass = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Canadian experience class</title>
                    <meta name="description" content="Canadian Experience Class is a category for individuals who has Canadian
                    experience as skilled workers in Canada" />
               </Helmet>
               <ImageCard img={ canEx }/>
               <TextCard title='Canadian' titleStrong='Experience Class'>
                    <p className='textCard__body'>The Canadian Experience Class is a category for individuals who has Canadian
                    experience as skilled workers in Canada. Most popular program for foreign
                    students and foreign workers.
                    </p>
                    <p className='textCard__body'><strong>Note:</strong> there is some restrictions regarding work experience such as self
                    employment and gained experience while full-time student.</p>
                    <p className='textCard__body'>Minimum requirements:</p>
                    <ul>
                         <li>Language ability</li>
                         <li>Have at least 1 year of skilled work experience in Canada, in the last 3 years
                         before you apply—you can meet this in a few different ways:
                              <ul>
                                   <li>full-time at 1 job: 30 hours/week for 12 months = 1 year full-time (1,560 hours)</li>
                                   <li>equal amount in part-time work: for example 15 hours/week for 24
                                   months = 1 year full time (1,560 hours)
                                        <ul>
                                             <li>You can work as many part-time jobs as you need to meet this requirement.</li>
                                        </ul>
                                   </li>
                                   <li>Full-time work at more than 1 job: 30 hours/week for 12 months at
                                   more than 1 job = 1 year full time (1,560 hours)</li>
                              </ul>
                         </li>
                         <li>Have gained your work experience by working in Canada while under temporary resident status with authorization to work</li>
                    </ul>
                    <p> More details regarding Canadian Experience Class can be found 
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/canadian-experience-class.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
}

export default CanExpClass
import React from 'react';

const PageList = ({ children }) => {
     return (
          <div className='pageList'>
               { children }
          </div>
     )
};

export default PageList;
import React from 'react';

import { 
     InfoCard, AboutCard, WhiteBackground, DoubleImgBlock, AboutBlock, 
     DarkBackground, OptionSlider, AssessmentCard, GreyBackground, 
     TestimonialsBlock, 
     SatisfactoryBlock} from '../components';

import img1 from '../assets/images/img1.webp';

const Main = () => {

     return (
          <div className='themeLayout main'>
               <div className='grid gr-t-c3 jus-c gap-2 align-c main__section-one'>
                    <InfoCard 
                         title='Online Free Assessment'
                         text='We are a trustworthy immigration Firm who can manage your case whether you wish to visit, live, work or study in Canada.'
                         iconStyle='largeIconWhite'
                         hrStyle='hr-white'
                    />
                    <InfoCard 
                         title='Book an Appointment'
                         text='To process your visa application with our experienced registered agents.'
                         iconStyle='largeIconWhite'
                         hrStyle='hr-white'
                    />
                    <InfoCard 
                         title='What We Offer'
                         text='Immigration consulting and quality work in addition to cost effective services.'
                         iconStyle='largeIconRed'
                         hrStyle='hr-black'
                    />
                    <AboutCard 
                         styles='gr-col-full'
                         baseText='Who we are'
                         title='Experienced Canada'
                         title2='Immigration Experts'
                         text='We help people from all over the world who wish to immigrate to Canada, 
                         either temporarily -  visit, work, study or permanently—through one of the 
                         various federal and provincial immigration programs.
'
                    />
               </div>
               <WhiteBackground>
                    <div className='grid gr-t-c3 jus-c gap-2 align-c'>
                         <div className='gr-col-full grid gr-t-c2 gap-2 main__section-two'>
                              <DoubleImgBlock />
                              <AboutBlock 
                                   title='Immigration Services From'
                                   title2='Experienced Experts'
                                   text='Aspen Immigration Inc is Canada based company offering immigration services for 
                                   temporary or permanent residency as well as providing professional advice with applications 
                                   for Canadian citizenship. Our expert is a Regulated Canadian Immigration Consultant (RCIC) R709194.'
                                   itemList={[
                                        'Talk to our experienced consultant today', 'We will help to find the most suitable immigration path for you',
                                        'We do our best to  help you get a positive decision on your case'
                                   ]}
                              />
                         </div>
                    </div>
               </WhiteBackground>
               <DarkBackground>
                    <div className='grid gr-t-c3 align-c jus-c'>
                         <div className='gr-col-full main__section-three'>
                              <AboutCard 
                                   styles='gr-col-full'
                                   baseText='Choose your immigration pathway'
                                   title='We will help you to'
                                   title2='Reach your goal'
                                   text='Since every case is different and has a particular set of circumstances, we evaluate each 
                                   immigration case individually, taking into account both the advantages and the disadvantages. 
                                   This evaluation enables us to offer our clients the best professional and immigration advice.'
                              />
                         </div>
                    </div>
               </DarkBackground>
               <DarkBackground>
                    <div className='padding-bottom25'>
                         <OptionSlider />
                    </div>
               </DarkBackground>
               <div className='grid gr-t-c3 align-c jus-c'>
                    <div className='gr-col-full'>
                         <AssessmentCard 
                              img={ img1 }
                              title='Free Online Assessment'
                              body='Contact us today by fill up free online visa assessment and we will contact you'
                              to='/assessment'
                              buttonBody='Assessment Form'
                         />
                    </div>
               </div>
               <GreyBackground>
                    <TestimonialsBlock />
               </GreyBackground>
               <DarkBackground>
                    <div className='grid gr-t-c3 align-c jus-c'>
                         <SatisfactoryBlock styles='gr-col-full'/>
                    </div>
               </DarkBackground>
          </div>
     )
};

export default Main;
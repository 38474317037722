export const assessmentValues = [
     {id: 1, name: "firstName", type: "text", label: "First Name", extra: false},
     {id: 2, name: "lastName", type: "text", label: "Last Name", extra: false},
     {id: 3, name: "email", type: "text", label: "Email", extra: false},
     {id: 4, name: "phone", type: "tel", label: "Phone #", country: "CA", extra: false},
     {id: 5, name: "age", type: "number", label: "Age", extra: false},
     {id: 6, name: "status", type: "radio", label: "Martial status", values: [
          {id: 6.1, name: "Married"},
          {id: 6.2, name: "Single/Never Married"},
          {id: 6.3, name: "Divorced"},
          {id: 6.4, name: "Separated"},
          {id: 6.5, name: "Common-law relationship"},
          {id: 6.6, name: "Widow"}
     ], extra: false},
     {id: 7, name: "cityOfResidence", type: "text", label: "City of residence", extra: false},
     {id: 8, name: "citizenship", type: "text", label: "Citizenship", extra: false},
     {id: 9, name: "educationLevel", type: "select", label: "What is your highest level of education?", options: [
          {id: 9.1, value: "Please make a selection"},
          {id: 9.2, value: "Less than secondary"},
          {id: 9.3, value: "Secondary school"},
          {id: 9.4, value: "Diploma/Certificate one year"},
          {id: 9.5, value: "Diploma/Certificate two year"},
          {id: 9.6, value: "Bachelors"},
          {id: 9.7, value: "Masters"},
          {id: 9.8, value: "PhD/Doctorate"},
          {id: 9.9, value: "More than two secondary designations"},
     ], extra: false},
     {id: 10, name: "fieldOfStudy", type: "text", label: "Field of study", extra: false},
     {id: 11, name: "postSecondaryCanada", type: "radio", label: "Have completed two years of Post Secondary study in Canada?", values: [
          {id: 11.1, name: "Yes"},
          {id: 11.2, name: "No"}
     ], extra: false},
     {id: 12, name: "eca", type: "radio", label: "Have you done ECA (Educational Credentials Assessment)?", values: [
          {id: 12.1, name: "Yes"},
          {id: 13.2, name: "No"}
     ], extra: false},
     {id: 13, name: "englishLevel", type: "radio", label: "English level proficience?", values: [
          {id: 13.1, name: "None"},
          {id: 13.2, name: "Beginner"},
          {id: 13.3, name: "Intermediate"},
          {id: 13.4, name: "Advanced"}
     ], extra: false},
     {id: 14, name: "frenchLevel", type: "radio", label: "French level proficience?", values: [
          {id: 14.1, name: "None"},
          {id: 14.2, name: "Beginner"},
          {id: 14.3, name: "Intermediate"},
          {id: 14.4, name: "Advanced"}
     ], extra: false},
     {id: 15, name: "skilledWorkOutside", type: "number", label: "How many years of Skilled work experience have you obtained OUTSIDE OF CANADA in the last 10 years?", extra: false},
     {id: 16, name: "jobTitle", type: "text", label: "Current Position/Job Title - How many years?", extra: false},
     {id: 17, name: "otherJobTitle", type: "text", label: "Other Skilled Position/Job Title - How many years?", extra: false},
     {id: 18, name: "workExpCanada", type: "radio", label: "Do you have Skilled work experience INSIDE OF CANADA?", values: [
          {id: 18.1, name: "None"},
          {id: 18.2, name: "1 year"},
          {id: 18.3, name: "2 year"},
          {id: 18.4, name: "3 year"},
          {id: 18.5, name: "4 year"},
          {id: 18.6, name: "5 year"},
          {id: 18.7, name: "5+ year"}
     ], extra: false},
     {id: 19, name: "dependent", type: "radio", label: "Do you have dependent children under 21 years old?", values: [
          {id: 19.1, name: "Yes"},
          {id: 19.2, name: "No"}
     ], extra: false},
     {id: 20, name: "dependentAge", type: "text", label: "Please list dependents and ages", extra: true, parent: "dependent", value: 'Yes', value2: ""},
     {id: 21, name: "relativeCanada", type: "text", label: "Do you have a Sibling (brother or sister) who lives in Canada and is a Canadian Citizen or Permenent Resident?", extra: false},
     {id: 22, name: "province", type: "text", label: "Do you have specific province you would like to immigrate to in Canada? If 'YES', please indicate. If 'NO', say ANY", extra: false},
     {id: 23, name: "spouseFirstName", type: "text", label: "Spouse First Name", extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 24, name: "spouseLastName", type: "text", label: "Spouse Last Name", extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 25, name: "spouseAge", type: "text", label: "Spouse Age", extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 26, name: "spouseEnglishLevel", type: "radio", label: "Spouse english level proficience?", values: [
          {id: 26.1, name: "None"},
          {id: 26.2, name: "Beginner"},
          {id: 26.3, name: "Intermediate"},
          {id: 26.4, name: "Advanced"}
     ], extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 27, name: "spouseFrenchLevel", type: "radio", label: "Spouse french level proficience?", values: [
          {id: 27.1, name: "None"},
          {id: 27.2, name: "Beginner"},
          {id: 27.3, name: "Intermediate"},
          {id: 27.4, name: "Advanced"}
     ], extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 28, name: "spouseEducationLevel", type: "select", label: "What is your spouse highest level of education?", options: [
          {id: 28.1, value: "Please make a selection"},
          {id: 28.2, value: "Less than secondary"},
          {id: 28.3, value: "Secondary school"},
          {id: 28.4, value: "Diploma/Certificate one year"},
          {id: 28.5, value: "Diploma/Certificate two year"},
          {id: 28.6, value: "Bachelors"},
          {id: 28.7, value: "Masters"},
          {id: 28.8, value: "PhD/Doctorate"},
          {id: 28.9, value: "More than two secondary designations"},
     ], extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 29, name: "spouseWorkExpCanada", type: "radio", label: "Spouse work experience INSIDE OF CANADA?", values: [
          {id: 29.1, name: "None"},
          {id: 29.2, name: "1 year"},
          {id: 29.3, name: "2 year"},
          {id: 29.4, name: "3 year"},
          {id: 29.5, name: "4 year"},
          {id: 29.6, name: "5 year"},
          {id: 29.7, name: "5+ year"}
     ], extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 30, name: "spouseWorkOutsideExpCanada", type: "radio", label: "Spouse work experience OUTSIDE OF CANADA?", values: [
          {id: 29.1, name: "None"},
          {id: 29.2, name: "1 year"},
          {id: 29.3, name: "2 year"},
          {id: 29.4, name: "3 year"},
          {id: 29.5, name: "4 year"},
          {id: 29.6, name: "5 year"},
          {id: 29.7, name: "5+ year"}
     ], extra: true, parent: "status", value: 'Married', value2: "Common-law relationship"},
     {id: 31, name: "additional", type: "textarea", label: "Additional Information", extra: false},
];
import React from 'react';

import img1 from '../../assets/images/img1.webp';
import student from '../../assets/images/student.webp';

const DoubleImgBlock = () => {
     return (
          <div className='doubleImgBlock'>
               <div><img src={ img1 } alt={ img1 } /></div>
               <div><img src={ student } alt={ student } /></div>
          </div>
     )
};

export default DoubleImgBlock;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import nurse from '../../assets/images/nurse.webp';

const Caregivers = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Caregivers</title>
                    <meta name="description" content="Canadian Experience Class is a category for individuals who has Canadian
                    experience as skilled workers in Canada" />
               </Helmet>
               <ImageCard img={ nurse }/>
               <TextCard title='Caregivers' titleStrong='Program'>
                    <p className='textCard__body'>The Home <strong>Child Care Provider Pilot</strong> and the <strong>Home Support Worker Pilot</strong>
                    are 5-year pilot programs that let qualified caregivers and their family members come to Canada with the goal 
                    of becoming permanent residents.</p>
                    <p className='textCard__body'>If you’ve been offered a job in Canada as a caregiver or have experience working in Canada 
                    as a caregiver, you may be able to apply for permanent residence through one of these pilots.</p>
                    <p className='textCard__body'>The application process will be different depending on your situation and 
                    how much qualifying work experience you have.</p>
                    <h3 className='textCard__body'><strong>Qualifying work experience</strong></h3>
                    <p className='textCard__body'>Qualifying work experience means you’ve worked full-time in Canada in one of 
                    these National Occupational Classification (NOC) jobs:</p>
                    <ul>
                         <li>
                              home child care provider - NOC 4411
                              <ul>
                                   <li>experience as a foster parent doesn’t count</li>
                              </ul>
                         </li>
                         <li>
                              home support worker - NOC 4412
                              <ul>
                                   <li>experience as a housekeeper doesn’t count</li>
                              </ul>
                         </li>
                    </ul>
                    <p className='textCard__body'>For more information please contact us and we will be happy to 
                    provide you with the most updated information regarding one of these Pilots.</p>
                    {/* <p className='textCard__body'>
                         More details regarding Express Entry can be found 
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/canadian-experience-class.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p> */}
               </TextCard>
          </React.Fragment>
     )
}

export default Caregivers;
import React from 'react';

const TextCard = ({ title, titleStrong, children }) => {
     return (
          <div className='textCard grid gap-1'>
               <h2 className='textCard__title'>{ title } <strong>{ titleStrong }</strong></h2>
               { children }
          </div>
     )
};

export default TextCard;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import superVisa from '../../assets/images/super-visa.webp';

const SuperVisa = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Super Visa</title>
                    <meta name="description" content="Super visa is specially designed for Parents 
                    and Grandparents for the period up to 10 years" />
               </Helmet>
               <ImageCard img={ superVisa }/>
               <TextCard title='Super' titleStrong='Visa'>
                    <p className='textCard__body'>Super visa is specially designed for Parents 
                    and Grandparents for the period up to 10 years who want to come to Canada on 
                    a temporary basis. The Super Visa allows to stay for up to 5 years at a time 
                    in Canada
                    </p>
                    <p>More details regarding Super Visa can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada/parent-grandparent-super-visa/after-apply-next-steps.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
};

export default SuperVisa;
import React from 'react';

import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';

const AboutBlock = ({ title, title2, subtitle, text, itemList }) => {
     return (
          <div className='aboutBlock flex align-start jus-c'>
               <div>
                    <p className='aboutBlock__default-text'>About Aspen Immigration</p>
                    <h2>{ title } <br/><span>{ title2 }</span></h2>
                    <h3>{ subtitle }</h3>
                    <p className='aboutBlock__text'>{ text }</p>
                    <ul>
                         {itemList?.map((item, index) => 
                                   <li key={ index } className='aboutBlock__bullets flex align-c'>
                                        <span className='flex align-c jus-s'><IoCheckmarkDoneCircleOutline className='themeIconMenu'/></span>
                                        <span className='flex align-c jus-s'>{ item }</span>
                                   </li>
                              )}
                    </ul>
               </div>
          </div>
     )
};

export default AboutBlock;
import React from 'react';
import { Routes, Route } from "react-router-dom";

import { DefaultPage, Main, Contact, ExpressEntry, 
     FederalSkilledWorker, FederalSkilledTrades, CanExpClass, PrNomPro,
     NotFound, StudyMain, Tourism, SuperVisa, SponsorshipMain, 
     CitizenshipMain, BusinessImmigration, LMIA, Caregivers } from '../pages/index';

const Router = () => {
     return (
          <Routes>
               <Route path='/' element={ <Main /> } />
               <Route path='/immigration' element={ <DefaultPage /> }>
                    <Route index element={ <ExpressEntry /> } />
                    <Route path='express-entry' element={ <ExpressEntry /> } />
                    <Route path='federal-skilled-worker' element={ <FederalSkilledWorker /> } />
                    <Route path='federal-skilled-trades' element={ <FederalSkilledTrades /> } />
                    <Route path='canadian-experience-class' element={ <CanExpClass /> } />
                    <Route path='provincial-nominee-programs' element={ <PrNomPro /> } />
               </Route>
               <Route path='/study' element={ <DefaultPage /> }>
                    <Route index element={ <StudyMain /> } />
               </Route>
               <Route path='/visit' element={ <DefaultPage /> }>
                    <Route index element={ <Tourism /> } />
                    <Route path='tourism' element={ <Tourism /> } />
                    <Route path='super-visa' element={ <SuperVisa /> } />
               </Route>
               <Route path='/sponsorship' element={ <DefaultPage /> }>
                    <Route index element={ <SponsorshipMain /> } />
               </Route>
               <Route path='/other-services' element={ <DefaultPage /> }>
                    <Route index element={ <BusinessImmigration /> } />
                    <Route path='canadian-business-immigration-program' element={ <BusinessImmigration /> } />
                    <Route path='labour-market-impact-assessment' element={ <LMIA /> } />
                    <Route path='caregivers' element={ <Caregivers /> } />
               </Route>
               <Route path='/citizenship' element={ <DefaultPage /> }>
                    <Route index element={ <CitizenshipMain /> } />
               </Route>
               <Route path='/contact-us' element={ <Contact /> } />
               <Route path='*' element={ <NotFound /> } />
          </Routes>
     )
}

export default Router;
import React, { useContext } from 'react';
import { ThemeContext } from '../../App';
import { RiFacebookFill, RiInstagramFill, RiTwitterFill, RiLinkedinBoxFill } from 'react-icons/ri';
import { GrMail } from 'react-icons/gr';
import license from '../../assets/images/lic.png'

const InfoLine = () => {
     const { assessmentFormToggle, contactFormToggle } = useContext(ThemeContext)
     
     return (
          <div className='grid gr-t-c2 infoLine'>
               <div className='flex jus-start gap-1 aling-c infoLine__section1'>
                    <div className='infoLine__email flex align-c gap-1' onClick={() => window.location = 'mailto:oredkina@aspenimmigration.ca'}>
                         <GrMail className='themeIcon'/>
                         <p>Send Us Email</p>
                    </div>
               </div>
               <div className='flex jus-end gap-1 infoLine__section2'>
                    <div className='infoLine__social flex align-c gap-1'>
                         <a href='https://college-ic.ca/protecting-the-public/find-an-immigration-consultant' target='_blank' rel='noreferrer'>
                              <img src={ license } alt='license' style={{ width: 100, height: 'auto' }}/>
                         </a>
                         <a href='https://www.facebook.com/aspen.immigration' target='_blank' rel='noreferrer'>
                              <RiFacebookFill className='themeIcon' />
                         </a>
                         <a href='https://www.instagram.com/aspen.immigration/?hl=en' target='_blank' rel='noreferrer'>
                              <RiInstagramFill className='themeIcon' />
                         </a>
                         <a href='https://twitter.com/aspenimm' target='_blank' rel='noreferrer'>
                              <RiTwitterFill className='themeIcon' />
                         </a>
                         <a href='https://www.linkedin.com/in/aspen-immigration-inc-94908a241/' target='_blank' rel='noreferrer'>
                              <RiLinkedinBoxFill className='themeIcon' />
                         </a>
                    </div>
                    <div className='flex jus-start gap-1 aling-c infoLine__section1' onClick={ contactFormToggle }>
                         <div className='infoLine__email flex align-c gap-1'>
                              <p>Book a consultation</p>
                         </div>
                    </div>
                    <div className='infoLine__request flex align-c gap-1' onClick={ assessmentFormToggle }>
                         <p>Free assessment</p>
                    </div>
               </div>
          </div>
     )
};

export default InfoLine;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import sponsorship from '../../assets/images/sponsorship.webp';

const SponsorshipMain = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Sponsorship</title>
                    <meta name="description" content="The Canadian government established this 
                    sponsorship category to support family reunion whenever possible." />
               </Helmet>
               <ImageCard img={ sponsorship }/>
               <TextCard title='Sponsorship' titleStrong='Program'>
                    <p className='textCard__body'>The Canadian government established this sponsorship 
                    category to support family reunion whenever possible.</p>
                    <p className='textCard__body'>Who can be sponsored under this category:</p>
                    <ul>
                         <li>Parents and grandparents</li>
                         <li>Dependant children</li>
                         <li>Spouses and common-law partners</li>
                         <li>Orphaned family members</li>
                         <li>Other family members under very specific circumstances</li>
                    </ul>
                    <h3><strong>Parents and Grandparents Sponsorship</strong></h3>
                    <p className='textCard__body'>Under this sponsorship program the permanent residents or 
                    Canadian citizen can  sponsor  parents and grandparents for permanent residency in Canada. 
                    Financial support criteria must be met as no social assistance available from the government. 
                    There are 10,000 applications per year available  to apply under this sponsorship program.</p>
                    <h3><strong>Spousal and Common-Law Partner Sponsorship</strong></h3>
                    <p className='textCard__body'>There is an eligibility criteria in order to  sponsor your spouse, common-law partner, or conjugal partner</p>
                    <p className='textCard__body'>Your spouse can be either sex and must be:</p>
                    <ul>
                         <li>legally married to you</li>
                         <li>at least 18 years old</li>
                    </ul>
                    <p className='textCard__body'>Your common-law partner:</p>
                    <ul>
                         <li>isn’t legally married to you</li>
                         <li>can be either sex</li>
                         <li>is at least 18 years old</li>
                         <li>
                              has been living with you for at least 12 consecutive months, meaning you’ve 
                              been living together continuously for 1 year in a conjugal relationship, 
                              without any long periods apart
                              <ul>
                                   <li>
                                        Any time spent away from each other should have been
                                        <ul>
                                             <li>short</li>
                                             <li>temporary</li>
                                        </ul>
                                   </li>
                              </ul>
                         </li>
                    </ul>
                    <p className='textCard__body'>Your conjugal partner:</p>
                    <ul>
                         <li>isn’t legally married to you or in a common-law relationship with you</li>
                         <li>can be either sex</li>
                         <li>is at least 18 years old</li>
                         <li>has been in a relationship with you for at least 1 year</li>
                         <li>lives outside Canada</li>
                         <li>
                              can't live with you in their country of residence or 
                              marry you because of significant legal and immigration reasons such as
                              <ul>
                                   <li>their marital status (for example, they’re still married to someone else in a country where divorce isn’t possible)</li>
                                   <li>their sexual orientation (for example, you are in a same-sex relationship, 
                                        and same-sex relationships are not accepted, or same-sex marriage is illegal where they live),</li>
                                   <li>persecution (for example, your relationship is between different religious groups which is
                                        not accepted and they may be punished legally or socially)</li>
                              </ul>
                         </li>
                    </ul>
                    <h3><strong>Dependent Child Sponsorship</strong></h3>
                    <p className="textCard__body">Children qualify as dependants if they meet both of these requirements:</p>
                    <ul>
                         <li>they’re under 22 years old</li>
                         <li>they don’t have a spouse or common law partner</li>
                    </ul>
                    <p className='textCard__body'>Children 22 years old or older qualify as dependants if they meet both of these requirements:</p>
                    <ul>
                         <li>they are unable to financially support themselves because of a mental or physical condition</li>
                         <li>they have depended on their parents for financial support since before the age of 22</li>
                    </ul>
                    <p className='textCard__body'>With the exception of age, your dependent child must continue to meet 
                         these requirements until we finish processing your application.</p>
                    <h3><strong>Orphaned Family Members and Other Siblings</strong></h3>
                    <p className="textCard__body">This unique program permits to sponsor an orphaned  sister or brother, nephew, niece, 
                         or grandchild if they are blood relatives and are under 18 years of age. Also this program allows to sponsor any 
                         relative of any age (every case is different and some conditions  may apply).</p>
                    <p>More details regarding Sponsorship Program can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/family-sponsorship.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
};

export default SponsorshipMain;
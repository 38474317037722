import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import trade from '../../assets/images/trade.webp';

const FederalSkilledTrades = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Federal skilled trades</title>
                    <meta name="description" content="Federal Skilled Trades Program Implemented for skilled workers who 
                         choose to become permanent residents based on being qualified in a skilled trade" />
               </Helmet>
               <ImageCard img={ trade }/>
               <TextCard title='Federal Skilled' titleStrong='Trades Program'>
                    <p className='textCard__body'>The Federal Skilled Trades Program Implemented for skilled workers who 
                         choose to become permanent residents based on being qualified in a skilled trade.
                    </p>
                    <p className='textCard__body'>Minimum requirements:</p>
                    <ul>
                         <li>Language Ability.</li>
                         <li>Have at least <strong>2 years</strong> of full-time work experience (or an equal amount of
                         part-time work experience) in a skilled trade <strong>within the 5 years</strong> before you apply</li>
                         <li>Meet the job requirements for that skilled trade as set out in the National
                         Occupational Classification, except for needing a certificate of qualification</li>
                         <li>Have a:
                              <ul>
                                   <li>Valid job offer of full-time employment for a total period of at least 1 year or</li>
                                   <li>Certificate of qualification in that skilled trade issued by a Canadian provincial, territorial or federal authority</li>
                              </ul>
                         </li>
                    </ul>
                    
                    <p>More details regarding Federal Skilled Trades Program can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/skilled-trades.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
}

export default FederalSkilledTrades
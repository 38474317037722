import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/pagination';

import calgary from '../../assets/images/calgary.webp'

const Testimonial = ({ name, country, body }) => {
     return (
          <div className=' testimonial grid align-c jus-c gap-1'>
               <div className='flex flex-col'>
                    <p className='testimonial__name'>{ name }</p>
                    <p className='testimonial__country'>{ country }</p>
               </div>
               <div className='grid align-c jus-c'>
                    <p className='testimonial__body'>{ body }</p>
               </div>
          </div>
     )
}

const TestimonialsBlock = ({ img }) => {
     return (
          <div className='testimonialsBlock grid gr-t-c2'>
               <div className='testimonialsBlock__leftBox grid'>
                    <div className='flex flex-col'>
                         <p className='testimonialsBlock__title'>Testimonial</p>
                         <p className='testimonialsBlock__subtitle'>What Our <span>Customers Say</span></p>
                    </div>
                    <Swiper
                         modules={[Autoplay, Pagination]}
                         slidesPerView={1}
                         pagination={true}
                         autoplay={true}
                         className='testimonialsSlider'
                    >
                         <SwiperSlide><Testimonial 
                              name='Zarah Ogali'
                              country='Nigeria'
                              body='I received my work permit and visa in a very short time period. 
                              I just completed a form and  Aspen Immigration did all the work. Very grateful!'
                         /></SwiperSlide>
                         <SwiperSlide><Testimonial 
                              name='Ana Faria'
                              country='Brazil'
                              body='I got help with my sponsorship application and I can not say thank you 
                              enough as we are finally reunited with my family!  Very knowledgeable and very professional. Highly recommend!'
                         /></SwiperSlide>
                         <SwiperSlide><Testimonial 
                              name='Naomi Sam'
                              country='South Korea'
                              body=' My study permit was approved and I am going to university in Vancouver. 
                              Aspen immigration is fast, well organized and super experienced experts. 
                              I found the process to be stress free. Definitely recommend using their service!'
                         /></SwiperSlide>
                         
                    </Swiper>
               </div>
               <div className='testimonialsBlock__rightBox'>
                    <div className='testimonialsBlock__imgBox'><img src={ calgary } alt='testimonials' /></div>
               </div>
          </div>
     )
};

export default TestimonialsBlock;
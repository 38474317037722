import React, { useState, useEffect } from 'react';
import { Desktop, Mobile } from './index';

const Navigation = () => {
     const [ width, setWidth ] = useState(window.innerWidth);

     useEffect(() => {
          window.addEventListener('resize', () => setWidth(window.innerWidth))
     },[])

     return (
          <div className='navigation'>
               { width < 960 
                    ? <Mobile />
                    : <Desktop />
               }  
          </div>
     )
};

export default Navigation;
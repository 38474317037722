export const menuItems = [
     { title: 'home', to: '/'},
     { title: 'immigration', to: '/immigration', submenu: [
          {title: 'Express Entry', to: '/immigration/express-entry'},
          {title: 'Federal Skilled Worker', to: '/immigration/federal-skilled-worker'},
          {title: 'Federal Skilled Trades', to: '/immigration/federal-skilled-trades'},
          {title: 'Canadian Experience Class', to: '/immigration/canadian-experience-class'},
          {title: 'Provincial Nominee Programs', to: '/immigration/provincial-nominee-programs'}
     ]}, 
     { title: 'study', to: '/study', submenu: []},
     { title: 'visit', to: '/visit', submenu: [
          {title: 'tourism', to: '/visit/tourism'},
          {title: 'super visa', to: '/visit/super-visa'}
     ]},
     { title: 'sponsorship', to: '/sponsorship', submenu: []}, 
     { title: 'citizenship', to: '/citizenship', submenu: [] },
     { title: 'other services', to: '/other-services', submenu: [
          {title: 'business immigration', to: '/other-services/canadian-business-immigration-program'},
          {title: 'LMIA', to: '/other-services/labour-market-impact-assessment'},
          {title: 'caregivers', to: '/other-services/caregivers'},
     ]},
     { title: 'contact us', to: '/contact-us', submenu: [] }];
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from "swiper";
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/free-mode';

import { MdOutlineFamilyRestroom, MdHealthAndSafety } from 'react-icons/md';
import { BsPersonLinesFill } from 'react-icons/bs';
import { FaUniversity } from 'react-icons/fa';
import { RiFilePaper2Fill } from 'react-icons/ri';

const OptionSlide = ({title, icon, text}) => {
     return (
          <div className='optionSlide grid'>
               <h3>{ title }</h3>
               <div className='flex align-c jus-c'>{ icon }</div>
               <p>{ text }</p>
          </div>
     )
}

const OptionSlider = () => {

     return (
          <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={true}
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
               640: {
                    slidesPerView: 2
                  },
               1024: {
                    slidesPerView: 3
                  },
               1260: {
                    slidesPerView: 4,
                  }
          }}
          className='optionSlider gr-col-full'
          >
               <SwiperSlide className='optionSlider__slide'>
                    <OptionSlide 
                    title='Study in Canada'
                    icon={ <FaUniversity className='largeIconWhite'/> }
                    text='Canada welcomes 600,000 + students every year. Interested in studying in Canada?'
                    />
               </SwiperSlide>
               <SwiperSlide className='optionSlider__slide'>
                    <OptionSlide 
                    title='Caregivers'
                    icon={ <MdHealthAndSafety className='largeIconWhite'/> }
                    text='Are you an experienced nurse or child care provider? This program might be suitable for you.'
                    />
               </SwiperSlide>
               <SwiperSlide className='optionSlider__slide'>
                    <OptionSlide 
                    title='Canadian Citizenship'
                    icon={ <RiFilePaper2Fill className='largeIconWhite'/> }
                    text={`Become proud Canadian. Did you know that with a Canadian passport you don't need a visa to visit more than 140 countries?!`}
                    />
               </SwiperSlide>
               <SwiperSlide className='optionSlider__slide'>
                    <OptionSlide 
                    title='Visitor Visa'
                    icon={ <BsPersonLinesFill className='largeIconWhite'/> }
                    text='Interested in exporting Canada or visiting family or friends?'
                    />
               </SwiperSlide>
               <SwiperSlide className='optionSlider__slide'>
                    <OptionSlide 
                    title='Super Visa'
                    icon={ <MdOutlineFamilyRestroom className='largeIconWhite'/> }
                    text='Would you like your parents or grandparents to be close to you? It is possible with a Super Visa application.'
                    />
               </SwiperSlide>
          </Swiper>
     )
}

export default OptionSlider;
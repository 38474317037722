import React from 'react';
import { useLocation } from 'react-router-dom';
import { RiHome5Line } from 'react-icons/ri';
import { MdOutlineArrowRightAlt } from 'react-icons/md';

import img1 from '../../assets/images/img1.webp'

const HeroBackground = () => {
     const { pathname } = useLocation();
     const path = pathname.split('/')
     const str = path[1].charAt(0).toUpperCase() + path[1].slice(1);
     const str2 = path[2] ? path[2].charAt(0).toUpperCase() + path[2].slice(1) : null;
     
     return (
          <div className='heroBg'>
               <img src={ img1 } alt='immigration page' />
               <div className='heroBg__contentBox'>
                    <h1 className='heroBg__title'>{ str2 ? str2.replace(/-/g, ' ') : str.replace(/-/g, ' ')}</h1>
                    <div className='heroBg__breadcrumb flex'>
                         <span className='flex align-c'><a href='/' className='flex align-c'><RiHome5Line className='smallIconwhite' /></a></span>
                         <span className='flex align-c'><MdOutlineArrowRightAlt className='smallIconwhite'/></span>
                         <span className='flex align-c'><a href={`/${path[1]}`}>{ str.replace(/-/g, ' ') }</a></span>
                         { str2
                              ? <React.Fragment>
                                   <span className='flex align-c'><MdOutlineArrowRightAlt className='smallIconwhite'/></span>
                                   <span className='flex align-c'>{ str2.replace(/-/g, ' ') }</span>
                              </React.Fragment>
                              : null
                         }
                    </div>
               </div>
               <div className='heroBg__overlay'></div>
          </div>
     )
};

export default HeroBackground;
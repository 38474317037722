import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import 'swiper/scss';
import "swiper/scss/effect-fade";

import people from '../../assets/images/people.webp';
import student from '../../assets/images/student.webp';
import visitors from '../../assets/images/visitors.webp';
import trade from '../../assets/images/trade.webp';

const SliderTitle = ({ styles }) => {
     return (
          <div className='sliderTitle'>
               <h1 className={ styles[0] }>Welcome to Aspen Immigration</h1>
               <h2 className={ styles[1] }>Immigration and Visa</h2>
               <p className={ styles[1] }>Services</p>
          </div>
     )
}

const HeaderSlider = () => {
     const [ sliderActionTitleStyle, setSliderActionTitleStyle ] = useState([]);

     return (
          <Swiper
               modules={[Autoplay, EffectFade]}
               autoplay={{
                    delay: 6800,
                    disableOnInteraction: false,
               }}
               centeredSlides={true}
               slidesPerView={1}
               grabCursor={true}
               loop={true}
               effect={"fade"}
               onSlideChange={() => {
                    setSliderActionTitleStyle(['slide-in-left', 'slide-in-bottom-right'])
                    setTimeout(() => {
                         setSliderActionTitleStyle(['slide-out-bck-center', 'slide-out-bck-center'])
                    }, 6000);
               }}
               className='headerSlider'
          >
               <SwiperSlide className='headerSlider__slide'>
                    <div>
                         <img src={ people } alt={ people }/>
                         <SliderTitle styles={ sliderActionTitleStyle }/>
                    </div>
               </SwiperSlide>
               <SwiperSlide className='headerSlider__slide'>
                    <div>
                         <img src={ student } alt={ student }/>
                         <SliderTitle styles={ sliderActionTitleStyle }/>
                    </div>
               </SwiperSlide>
               <SwiperSlide className='headerSlider__slide'>
                    <div>
                         <img src={ visitors } alt={ visitors }/>
                         <SliderTitle styles={ sliderActionTitleStyle }/>
                    </div>
               </SwiperSlide>
               <SwiperSlide className='headerSlider__slide'>
                    <div>
                         <img src={ trade } alt={ trade }/>
                         <SliderTitle styles={ sliderActionTitleStyle }/>
                    </div>
               </SwiperSlide>
          </Swiper>
     )
};

export default HeaderSlider;
import React, { useEffect, useState } from 'react';

const CircularProgressBar = ({ size, strokeWidth, percentage }) => {
     const [progress, setProgress] = useState(0);
     
     useEffect(() => {
          setProgress(percentage);
     }, [percentage]);

     const viewBox = `0 0 ${ size } ${ size }`;
     const radius = (size - strokeWidth) / 2;
     const circumference = radius * Math.PI * 2;
     const dash = (progress * circumference) / 100;

     return (
          <svg width={ size } height={ size } viewBox={ viewBox }>
               <circle 
                    fill='none'
                    stroke='#fff'
                    cx={ size / 2 }
                    cy={ size / 2 }
                    r={ radius }
                    strokeWidth={`${ strokeWidth }px`}
               />
               <circle
                    fill="none"
                    stroke='#d80621'
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    strokeDasharray={[dash, circumference - dash]}
                    strokeLinecap="round"
                    style={{ transition: "all 0.5s" }}
               />
               <text
                    fill="#fff"
                    fontSize="16px"
                    x="50%"
                    y="50%"
                    dy="8px"
                    textAnchor="middle"
               >
                    {`${percentage}%`}
               </text>
          </svg>
     )
}

export default CircularProgressBar
import React from 'react';

const ThemeButton = ({ to, body, func }) => {
     
     return (
          <div className='themeButton flex align-c jus-c' onClick={ func }>
               <p to={ to } className='themeButton__body'>{ body }</p>
          </div>
     )
};

export default ThemeButton;

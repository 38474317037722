import React from 'react';

import { CircularProgressBar } from '../index';

const SatisfactoryBlock = ({ styles }) => {
     return (
          <div className={`${ styles } flex align-c jus-start satisfactoryBlock`}>
               <div className='flex align-c jus-c'>
                    <CircularProgressBar size={75} strokeWidth={ 3 } percentage={90}/>
                    <p>Our Satisfied Customers</p>
               </div>
               <div className='flex align-c jus-c'>
                    <CircularProgressBar size={75} strokeWidth={ 3 } percentage={90}/>
                    <p>Projects are Completed</p>
               </div>
          </div>
     )
};

export default SatisfactoryBlock;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import lmia from '../../assets/images/lmia.webp';

const LMIA = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Labour Market Impact Assessment</title>
                    <meta name="description" content="An employer in Canada must get a Canadian LMIA before hiring the majority of 
                    foreign workers." />
               </Helmet>
               <ImageCard img={ lmia }/>
               <TextCard title='Labour Market' titleStrong='Impact Assessment'>
                    <p className='textCard__body'>An employer in Canada must get a Canadian LMIA before hiring the majority of 
                    foreign workers. A successful LMIA verifies that a foreign worker is required to complete the task and that 
                    no Canadian workers are available to carry it out. A temporary foreign worker has six months to use the LMIA 
                    to submit an application for a work permit after receiving a positive LMIA.</p>
                    <h3><strong>How to get an LMIA</strong></h3>
                    <p className='textCard__body'>You’ll need an LMIA from Employment and Social Development Canada (ESDC).</p>
                    <p className='textCard__body'>The LMIA application process depends on the type of program you’re hiring through.</p>
                    <p className='textCard__body'>There are different applications  for an LMIA to hire:</p>
                    <ul>
                         <li>high-wage workers</li>
                         <li>low-wage workers</li>
                         <li>workers through the Seasonal Agricultural Worker Program</li>
                         <li>workers through the Agricultural Stream</li>
                    </ul>
                    <p className='textCard__body'>Before granting a job to a temporary foreign worker, LMIA applicants must be able 
                    to demonstrate their efforts in employing Canadians and permanent citizens.</p>
                    <p className='textCard__body'>Before offering a job to a temporary foreign worker, the employer must fulfill 
                    the recruitment procedures that show their efforts in hiring Canadians and permanent citizens.</p>
                    {/* <p className='textCard__body'>
                         More details regarding Express Entry can be found 
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/canadian-experience-class.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p> */}
               </TextCard>
          </React.Fragment>
     )
}

export default LMIA;
import React from 'react';

const AboutCard = ({ baseText, title, text, title2, styles }) => {
     return (
          <div className={`aboutCard grid gr-t-c2 jus-sp-around gap-2 ${ styles }`}>
               <div className='flex flex-col'>
                    <p className='aboutCard__baseText'>{ baseText }</p>
                    <h3>{ title }<br/><span>{ title2 }</span></h3>
               </div>
               <p className='aboutCard__text'>{ text }</p>
          </div>
     )
};

export default AboutCard;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import business from '../../assets/images/business.jpg';

const BusinessImmigration = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Canadian Business Immigration Program</title>
                    <meta name="description" content="The Canadian Business Immigration Program was established 
                    to provide new opportunities in local markets" />
               </Helmet>
               <ImageCard img={ business }/>
               <TextCard title='Canadian Business' titleStrong='Immigration Program'>
                    <p className='textCard__body'>The Canadian Business Immigration Program was established 
                    to provide new opportunities in local markets, by inviting  skilled, creative and experienced people.
                    </p>
                    <p className='textCard__body'>Canadian Business Immigration Program is made to help encourage and thrive 
                    Canada’s economy by engaging entrepreneurs, the self-employed and businesspersons from all over the world.</p>
                    <p className='textCard__body'>There are different pathways recognized under Canadian Business Class:</p>
                    <ul>
                         <li>Start-up Visa</li>
                         <li>Self-employed Program</li>
                         <li>Business Visitor Visa</li>
                         <li>PNP Entrepreneur Visa Streams</li>
                    </ul>
                    <h3><strong>Start-up Visa</strong></h3>
                    <p className='textCard__body'>Canada’s Start-up Visa Program targets immigrant entrepreneurs with the 
                    skills and potential to build businesses in Canada that:</p>
                    <ul>
                         <li>are innovative</li>
                         <li>can create jobs for Canadians</li>
                         <li>can compete on a global scale</li>
                    </ul>
                    <h3><strong>Self-Employed Program</strong></h3>
                    <p className='textCard__body'>The Self-employed Persons Program gives people the opportunity
                    to immigrate to Canada permanently as a self-employed person.</p>
                    <p className='textCard__body'>To be eligible you must:</p>
                    <ul>
                         <li>have relevant experience in cultural activities or athletics</li>
                         <li>be willing and able to make a significant contribution to the cultural or athletic life of Canada</li>
                    </ul>
                    <h3><strong>PNP Entrepreneur Visa</strong></h3>
                    <p className='textCard__body'>Individuals who can invest in Canada  are considered business immigrants, which helps the country's 
                    economy grow and prosper. The majority of provinces have their own immigration programmes for entrepreneurs 
                    under the Canada PNP. Successful candidates and their families will obtain permanent residence in Canada.</p>
                    {/* <p className='textCard__body'>
                         More details regarding Express Entry can be found 
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/canadian-experience-class.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p> */}
               </TextCard>
          </React.Fragment>
     )
}

export default BusinessImmigration;
import React, { useState } from "react";
import { Header, AssessmentForm, Footer, ContactFormPopWindow } from "./components";
import Router from './routes/Router';

export const ThemeContext = React.createContext();

const App = () => {
     const [ assessmentFormActive, setAssessmentFormActive ] = useState(false);
     const [ contactFormActive, setContactFormActive ] = useState(false);
     
     return (
          <ThemeContext.Provider value={{ 
               assessmentFormActive,
               contactFormActive,
               assessmentFormToggle: () => setAssessmentFormActive(!assessmentFormActive),
               contactFormToggle: () => setContactFormActive(!contactFormActive)
          }}>
               
               { assessmentFormActive 
                    ? <AssessmentForm /> 
                    : <React.Fragment>
                         <div className='grid'>
                              <Header />
                         </div>
                         {/* <SideMenu /> */}
                         <Router />
                         <Footer />
                         { contactFormActive ? <ContactFormPopWindow /> : false }
                    </React.Fragment>
               }
          </ThemeContext.Provider>
     )
}

export default App;

import React from 'react';

const ImageCard = ({ img }) => {
     return (
          <div className='imageCard'>
               <img src={ img } alt='title' className='imageCard__img'/>
          </div>
     )
};

export default ImageCard;
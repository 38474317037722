import React, { useContext } from 'react';
import { RiFacebookFill, RiInstagramFill, RiTwitterFill, RiLinkedinBoxFill } from 'react-icons/ri';

import { ThemeContext } from '../../App';
import DarkBackground from '../backgrounds/DarkBackground';
import logo from '../../assets/images/logo.png';
import license from '../../assets/images/lic.png';


const Footer = () => {
     const { assessmentFormToggle } = useContext(ThemeContext)

     return (
          <div className='themeLayout footer'>
               <DarkBackground>
                    <div className='grid gr-t-c3 jus-c gap-2 footer__padding'>
                              <div className='grid gap-2 footer__contentBox'>
                                   <a href='/' className='flex align-c footer__imgBox'>
                                        <img src={ logo } alt='aspenimmigration logo' className='footer__logo'/>
                                   </a>
                                   <p className='flex align-c footer__text'>Aspen Immigration offers a straightforward method 
                                   of processing any types of visa needs.</p>
                                   <div className='flex align-c footer__iconsBox'>
                                        <a href='https://www.facebook.com/aspen.immigration' target='_blank' rel='noreferrer' className='flex align-c jus-c'>
                                             <RiFacebookFill className='smallIconwhite' />
                                        </a>
                                        <a href='https://www.instagram.com/aspen.immigration/?hl=en' target='_blank' rel='noreferrer' className='flex align-c jus-c'>
                                             <RiInstagramFill className='smallIconwhite' />
                                        </a>
                                        <a href='https://twitter.com/aspenimm' target='_blank' rel='noreferrer' className='flex align-c jus-c'>
                                             <RiTwitterFill className='smallIconwhite' />
                                        </a>
                                        <a href='https://www.linkedin.com/in/aspen-immigration-inc-94908a241/' target='_blank' rel='noreferrer' className='flex align-c jus-c'>
                                             <RiLinkedinBoxFill className='smallIconwhite' />
                                        </a>
                                   </div>
                              </div>
                              <div className='footer__contentBox'>
                                   <p className='flex align-c jus-c footer__title'>Navigation</p>
                                   <a href='/' className='flex align-c jus-c footer__link'>Home</a>
                                   <a href='/immigration' className='flex align-c jus-c footer__link'>Immigration</a>
                                   <a href='/study' className='flex align-c jus-c footer__link'>Study</a>
                                   <a href='/Visit' className='flex align-c jus-c footer__link'>Visit</a>
                                   <a href='/sponsorship' className='flex align-c jus-c footer__link'>Sponsorship</a>
                                   <a href='/citizenship' className='flex align-c jus-c footer__link'>Citizenship</a>
                                   <a href='/contact-us' className='flex align-c jus-c footer__link'>Contact us</a>
                                   <p className='flex align-c jus-c footer__link' onClick={assessmentFormToggle}>Assessment Form</p>
                              </div>
                              <div className='footer__contentBox'>
                                   <p className='flex align-c jus-c footer__title'>Get in Touch</p>
                                   <p className='flex align-c jus-c footer__link' onClick={() => window.location = 'mailto:oredkina@aspenimmigration.ca'}>Send Us Email</p>
                                   <p className='flex align-c jus-c footer__link' onClick={() => window.location = 'tel:+14036075070'}>+1(403)-607-5070</p>
                              </div>
                    </div>
                    <div className='grid gr-t-c3 jus-c gap-2 align-c'>
                         <div className='gr-col-full'>
                              <hr />
                         </div>
                    </div>
                    <div className='grid gr-t-c3 jus-c gap-2 align-c'>
                         <div className='grid gap-2 footer__contentBox'>
                              <a href='https://college-ic.ca/protecting-the-public/find-an-immigration-consultant' className='flex align-c footer__imgBox' target='_blank' rel='noreferrer'>
                                   <img src={ license } alt='aspenimmigration license' className='footer__logo'/>
                              </a>
                         </div>
                         <div className='grid gap-2 footer__contentBox'></div>
                         <div className='grid gap-2 footer__contentBox'>
                              <a href='/Visit' className='flex align-c jus-c footer__link'>Copyright © 2022 All Rights Reserved.</a>
                         </div>
                    </div>
               </DarkBackground>
          </div>
     )
};

export default Footer;
import React, { useEffect, useState, useRef } from 'react';
import { BiConversation } from 'react-icons/bi';
import { menuItems } from '../data/data';
import { Logo } from '../../index';


const SubMenu = ({ subMenuItems }) => {
     
     return (
          <React.Fragment>
               {  !subMenuItems 
                    ?    null
                    :    <div className={`subNav grid align-c jus-start slide-in-top`}>
                              {subMenuItems.map(subMenuItem => 
                                   <a href={`${ subMenuItem.to }`} className='subNav__subitem' key={ subMenuItem + Math.random() }>{ subMenuItem.title }</a>
                              )}
                         </div> 
               }
          </React.Fragment>
     )
}



const Menu = () => {
     const [subMenuItems, setSubMenuItems] = useState();
     const [ target, setTarget ] = useState(); // GET TARGET TEXT TO COMPARE WITH DATA ITEMS AND SHOW SUB MENU

     return (
          <div className='nav flex'>
               { menuItems.map(menuItem => 
                    <span 
                         key={ menuItem + Math.random() } 
                         className='flex align-c jus-c'
                         onMouseEnter={(e) => {
                              setSubMenuItems(menuItem.submenu);
                              setTarget(e.target.textContent);
                         }}
                         onMouseLeave={() => setSubMenuItems(null)}
                    >
                         <a href={`${ menuItem.to }`}>{ menuItem.title }</a>
                         {target === menuItem.title ? <SubMenu subMenuItems={ subMenuItems }/> : null}
                    </span>
               )}
          </div>
     )
}

const Desktop = () => {
     const desktopNav = useRef();
     const [ isTopStyle, setIsTopStyle ] = useState();

     useEffect(() => {
          const handleScroll = () => {
               if(window.scrollY <= 60) {
                    setIsTopStyle('');
               } else if (desktopNav.current.offsetTop - window.scrollY <= 0) {
                    setIsTopStyle('desktop-fixed');
               }
          }

          window.addEventListener('scroll', handleScroll)

          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     },[]);

     return (
          <div className={`desktop grid gr-minmax align-c jus-sp-bt ${ isTopStyle }`} ref={ desktopNav }>
               <div className='flex desktop__section1'>
                    <Logo styles={ isTopStyle ? 'desktop-fixed__logo' : '' }/>
                    <Menu />
               </div>
               <div className='flex jus-end desktop__section2 jus-c align-c gap-1' onClick={() => window.location = 'tel:+14036075070'}>
                    <BiConversation className='largeIconWhite'/>
                    <div className='desktop__contact flex flex-col align-c jus-c'>
                         <p>Have any Questions?</p>
                         <p>+1(403)-607-5070</p>
                    </div>
               </div>
          </div>
     )
};

export default Desktop;
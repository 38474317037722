import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import visitors from '../../assets/images/visitors.webp';

const Tourism = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Visitor Visa / Tourism</title>
                    <meta name="description" content="You can travel to Canada as a visitor, to see family, or to conduct 
                    business." />
               </Helmet>
               <ImageCard img={ visitors }/>
               <TextCard title='Visitor' titleStrong='Visa'>
                    <p className='textCard__body'>Every year, Canada welcomes approximately 60 million visitors 
                    from throughout the world. You can travel to Canada as a visitor, to see family, or to conduct 
                    business. You must meet certain immigration standards whether you are visiting Canada for a short term or long term.
                    </p>
                    <p className='textCard__body'>Canada offers a variety of visas that allow you to visit friends and relatives, 
                    as well as some unique visas that allow you to engage in other activities such as short-term study or business. 
                    Temporary resident visas can be issued for a single visit or multi-visa for a certain amount of time. Learn about 
                    the many types of visitor visas and how to prolong your stay.
                    </p>
                    <p> More details regarding Visitor Visa can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/visit-canada.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
}

export default Tourism
import React, { useState, useContext } from 'react';
import { ThemeContext } from '../../App';
import { useForm } from "react-hook-form";
import { sendMail } from '../../api/api';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const ContactForm = () => {
     const { register, handleSubmit, reset } = useForm();
     const [ value, setValue ] = useState();
     const [ response, setResponse ] = useState('');
     const { contactFormToggle } = useContext(ThemeContext);

     const onSubmit = data => {
          data = { ...data, ...{ phone: value ? value : '', type: 'contact' } }
          setResponse({ message: "Message sending... Please wait" });
          sendMail(data)
               .then(res => {
                    setResponse(res.data);
                    setTimeout(() => {
                         setResponse('')
                         reset()
                         setValue('')
                         contactFormToggle()
                    }, 2000)
               }).catch(error => {
                    setResponse(error.response.data.error);
                    setTimeout(() => {
                         setResponse('')
                    }, 3000)
               })
     };

     return (
          <form className='contactForm grid' method='post' onSubmit={handleSubmit(onSubmit)}>
               <div className='contactForm__inputsBox'>
                    <div><h2 className='contactForm__title'>Contact Us <strong>Regarding Immigration</strong></h2></div>
                    <div>
                         <input 
                              className={`contactForm__input ${response.param === 'fullName' ? 'contactForm__inputError' : ''}`}
                              type="text" 
                              placeholder="Full name" 
                              {...register("fullName")} 
                         />
                         <p className='contactForm__error flex align-c'>{ response.param === 'fullName' ? response.msg : '' }</p>
                    </div>
                    <div>
                         <PhoneInput
                              placeholder="Enter phone number"
                              value={value}
                              onChange={setValue}
                              type="tel"
                              className={`${response.param === 'phone' ? 'contactForm__PhoneError' : ''}`}
                         />
                         <p className='contactForm__error flex align-c'>{ response.param === 'phone' ? response.msg : ''  }</p>
                    </div>
                    <div>
                         <input 
                              className={`contactForm__input ${response.param === 'email' ? 'contactForm__inputError' : ''}`}
                              type="email" 
                              placeholder="Email" 
                              {...register("email")} 
                              />
                         <p className='contactForm__error flex align-c'>{ response.param === 'email' ? response.msg : ''  }</p>
                    </div>
                    <div>
                         <textarea 
                         className='contactForm__textarea'
                         placeholder='Type your Question'
                         {...register("body")} 
                         />
                         <p className='contactForm__error flex align-c'>{ response.param === 'body' ? response.msg : ''  }</p>
                    </div>
                    <div className='flex jus-c'>
                         <p className='contactForm__success flex align-c'>{ response.param === 'success' ? response.message : ''  }</p>
                    </div>
                    <div className='flex jus-c'>
                         <input type="submit" value='Send Message' className='contactForm__button' />
                    </div>
               </div>
          </form>
     )
};

export default ContactForm;
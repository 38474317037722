import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spin as Hamburger } from 'hamburger-react';
import { ThemeContext } from '../../App';
import PhoneInput from 'react-phone-number-input';
import { sendRequest } from '../../api/api';

import { assessmentValues } from './asseessmentValue';

const AssessmentForm = () => {
     const { register, handleSubmit, watch, reset, setFocus} = useForm();
     const [ value, setValue ] = useState();
     const [ response, setResponse ] = useState('');
     const { assessmentFormActive, assessmentFormToggle } = useContext(ThemeContext);
     const watchFields = watch();


     useEffect(() => {
          setFocus("firstName");
     }, [setFocus]);

     const onSubmit = async data => {
          data = { ...data, ...{ phone: value ? value : '', type: 'request' } }
          setResponse({ message: "Message sending... Please wait" });
          
          sendRequest(data)
               .then(res => {
                    setResponse(res.data);
                    setTimeout(() => {
                         setResponse('')
                         reset()
                         setValue('')
                         assessmentFormToggle();
                    }, 2000)
               }).catch(error => {
                    setResponse(error.response.data.error);
                    setFocus(error.response.data.error.param);
                    setTimeout(() => {
                         setResponse('')
                    }, 8000)
               })
     };
     
     return (
          <div className='themeLayout assessmentForm'>
               <div className='assessmentForm__iconBox'>
                    <Hamburger 
                         toggled={assessmentFormActive} 
                         onToggle={assessmentFormToggle} 
                         size={28}
                    />
               </div>
               <form onSubmit={handleSubmit(onSubmit)} className='assessmentForm__form grid jus-c gap-2'>
                    {
                         assessmentValues?.map(input => {
                              if (input.type === 'tel'){
                                   return (
                                        <div key={ input.id }>
                                             <label htmlFor={ input.name } className="assessmentForm__label grid align-c">
                                                  { input.label }
                                                  <PhoneInput name={ input.name } value={value} onChange={setValue} type={ input.type } className={`${response.param === input.name ? 'assessmentForm__PhoneError' : ''}`}/>
                                             </label>
                                             <p className={`assessmentForm__error`}>{ response.param === input.name ? response.msg : '' }</p>
                                        </div>
                                   )
                              } else if (input.type === 'textarea'){
                                   return (
                                        <div key={ input.id }>
                                             <label htmlFor={ input.name } className="assessmentForm__label grid align-c">
                                             { input.label }
                                                  <textarea name={ input.name } { ...register(input.name)} className={`assessmentForm__textarea ${response.param === input.name ? 'assessmentForm__inputError' : ''}`}/>
                                             </label>
                                             <p className={`assessmentForm__error`}>{ response.param === 'editional' ? response.msg : '' }</p>
                                        </div>
                                   )
                              } else if(input.type === 'radio'){
                                   if (input.extra === true){
                                        return watchFields[input.parent] === input.value || watchFields[input.parent] === input.value2 
                                        ? <div key={ input.id }>
                                             <fieldset className='assessmentForm__fieldset'>
                                                  <legend>{ input.label }</legend>
                                                  { input.values.map(value => {
                                                       return (
                                                            <label htmlFor={ input.name } className="assessmentForm__label flex align-c" key={ value.id }>
                                                                 <input {...register(input.name)} type={ input.type } value={value.name} className='assessmentForm__checkbox'/>
                                                                 { value.name }
                                                            </label>
                                                       )
                                                  }) }
                                             </fieldset>
                                             <p className={`assessmentForm__error`}>{ response.param === input.name ? response.msg : '' }</p>
                                        </div>
                                        : false
                                   } else {
                                        return (
                                        <div key={ input.id }>
                                             <fieldset className='assessmentForm__fieldset'>
                                                  <legend>{ input.label }</legend>
                                                  { input.values.map(value => {
                                                       return (
                                                            <label htmlFor={ input.name } className="assessmentForm__label flex align-c" key={ value.id }>
                                                                 <input {...register(input.name)} type={ input.type } value={value.name} className='assessmentForm__checkbox'/>
                                                                 { value.name }
                                                            </label>
                                                       )
                                                  }) }
                                             </fieldset>
                                             <p className={`assessmentForm__error`}>{ response.param === input.name ? response.msg : '' }</p>
                                        </div>
                                   )}
                              } else if (input.type === 'select'){
                                   if(input.extra === true){
                                        return watchFields[input.parent] === input.value || watchFields[input.parent] === input.value2 
                                        ? <div key={ input.id }>
                                             <label htmlFor={ input.name } className="assessmentForm__label grid align-c">
                                                  { input.label }
                                                  <select name={ input.name } {...register(input.name)} className={`assessmentForm__input ${response.param === input.name ? 'assessmentForm__inputError' : ''} assessmentForm__select}`}>
                                                       {  input.options.map(option => {
                                                            return (
                                                                 <option value={ option.value } key={ option.id }>{ option.value }</option>
                                                            )
                                                       })}
                                                  </select>
                                             </label>
                                             <p className={`assessmentForm__error`}>{ response.param === input.name ? response.msg : '' }</p>
                                        </div> : false
                                   } else {
                                        return (
                                             <div key={ input.id }>
                                                  <label htmlFor={ input.name } className="assessmentForm__label grid align-c">
                                                       { input.label }
                                                       <select name={ input.name } {...register(input.name)} className={`assessmentForm__input ${response.param === input.name ? 'assessmentForm__inputError' : ''} assessmentForm__select}`}>
                                                            {  input.options.map(option => {
                                                                 return (
                                                                      <option value={ option.value } key={ option.id }>{ option.value }</option>
                                                                 )
                                                            })}
                                                       </select>
                                                  </label>
                                                  <p className={`assessmentForm__error`}>{ response.param === input.name ? response.msg : '' }</p>
                                             </div>
                                        )
                                   }
                              } 
                              else {
                                   if( input.extra === true){
                                        return watchFields[input.parent] === input.value || watchFields[input.parent] === input.value2 ?
                                                  <div key={ input.id }>
                                                       <label htmlFor={ input.name } className="assessmentForm__label grid align-c">
                                                            { input.label }
                                                            <input name={ input.name } type={ input.type } {...register(`${ input.name }`)} className={`assessmentForm__input ${response.param === input.name ? 'assessmentForm__inputError' : ''}`}/>
                                                       </label>
                                                       <p className={`assessmentForm__error flex align-c`}>{ response.param === input.name ? response.msg : '' }</p>
                                                  </div> : false
                                              
                                   } else {
                                        return (
                                             <div key={ input.id }>
                                                  <label htmlFor={ input.name } className="assessmentForm__label grid align-c">
                                                       { input.label }
                                                       <input name={ input.name } type={ input.type } {...register(`${ input.name }`)} className={`assessmentForm__input ${response.param === input.name ? 'assessmentForm__inputError' : ''}`}/>
                                                  </label>
                                                  <p className={`assessmentForm__error flex align-c`}>{ response.param === input.name ? response.msg : '' }</p>
                                             </div>          
                                        )
                                   }
                              }
                         })
                    }
                    
                    <div className='flex jus-c'>
                         <p className='assessmentForm__success flex align-c'>{ response.message }</p>
                    </div>
                    <div className='flex jus-c'>
                         <input type="submit" value='Send Request' className='assessmentForm__button' />
                    </div>
               </form>
          </div>
     )
};

export default AssessmentForm;
import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Helmet } from 'react-helmet';

import { menuItems } from '../components/nav/data/data';
import { ContactForm, PageList } from '../components/index';


const DefaultPage = () => {
     const { pathname } = useLocation();
     const [ menu ] = menuItems.filter(menuItem => menuItem.to === `/${pathname.split('/')[1]}`);
     const scrollRef = useRef(null);

     const capitalize = (value) => {
          return value.charAt(0).toUpperCase() + value.slice(1);
     };

     useEffect(() => {
          const executeScroll = () => scrollRef.current.scrollIntoView();
          executeScroll()
     },[])

     return (
          <div className='themePageLayout' ref={ scrollRef }>
               <Helmet>
                    <title>{ capitalize(menu.title) }</title>
               </Helmet>
               <div className='col-1 flex flex-col'>
                    <div style={{zIndex: 10}}>
                         <PageList>
                              { menu.submenu.map(subMenuItem => 
                                   <a href={`${subMenuItem.to}`} key={ subMenuItem + Math.random() } className={`pageList__item flex jus-sp-bt ${ pathname === subMenuItem.to ? 'pageList__item-active' : null}`}>
                                        <span>{ subMenuItem.title}</span>
                                        <span><MdKeyboardArrowRight /></span>
                                   </a>
                              )}
                         </PageList>
                    </div>
                    <div>
                         <ContactForm />
                    </div>
               </div>
               <div className='col-2'>
                    <Outlet />
               </div>
          </div>
     )
};

export default DefaultPage;
import React from 'react';
import { Helmet } from 'react-helmet';
import { ImageCard, TextCard } from '../../components';
import federalSkilledWorker from '../../assets/images/federal-skilled-worker.webp';

const FederalSkilledWorker = () => {
     return (
          <React.Fragment>
               <Helmet>
                    <title>Federal skilled worker</title>
                    <meta name="description" content="Federal Skilled Worker Class is the most popular category" />
               </Helmet>
               <ImageCard img={ federalSkilledWorker }/>
               <TextCard title='Federal Skilled' titleStrong='Worker Program'>
                    <p className='textCard__body'>Federal Skilled Worker Class is the most popular category. 
                    Applicants must meet minimum criteria. Applicants need minimum 67 points to be eligible to apply.
                    </p>
                    <p className='textCard__body'>Skilled workers are selected as permanent residents based on their:</p>
                    <ul>
                         <li>Education</li>
                         <li>Work experience</li>
                         <li>Language ability and other factors</li>
                    </ul>
                    <p className='textCard__body'>In addition to selection criteria, applicant needs to demonstrate 
                    sufficient funds available for resettlement in Canada. Once IRCC has determined that an applicant is
                    a member of the Federal Skilled Worker Class, along with application the medical examinations and 
                    criminal and security checks are required.</p>
                    <p> More details regarding Federal Skilled Worker Program can be found
                         <a href='https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/federal-skilled-workers.html' 
                         rel="noreferrer" target='_blank'> here
                         </a>.
                    </p>
               </TextCard>
          </React.Fragment>
     )
}

export default FederalSkilledWorker
import React from 'react';
import { GiBookmarklet } from 'react-icons/gi';

const InfoCard = ({ title, text, iconStyle, hrStyle }) => {
     return (
          <div className={`infoCard grid align-c jus-c`}>
               <div className='grid gr-t-c2-25-75 jus-sp-around jus-it-c align-c'>
                    <GiBookmarklet className={ iconStyle }/>
                    <h3>{ title }</h3>
               </div>
               <hr className={ hrStyle }/>
               <div>
                    <p>{ text }</p>
               </div>

          </div>
     )
}

export default InfoCard;